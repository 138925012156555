import React from 'react';

const FacebookSvg = ({ className }) => (
  <svg
    id="Layer_1"
    className={className}
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 39.58 75.81"
    width="1em"
    height="1em"
  >
    <title>Icons</title>
    <path
      fill="currentColor"
      className="cls-1"
      d="M11.68,58.35c0-4.58,0-9.16,0-13.73,0-3.18-.06-3.19-3.34-3.25-2,0-3.92,0-5.88,0C.91,41.4,0,41,0,39.19c.06-3.14,0-6.28,0-9.42,0-1.46.6-2.16,2.09-2.14,2.35,0,4.71-.07,7.06,0,1.79.07,2.64-.47,2.55-2.43-.12-2.74,0-5.5,0-8.24C11.61,5.13,20.74-.51,29.06,0c2.74.17,5.49.16,8.23.29a2.12,2.12,0,0,1,2.28,2.51c-.08,2.61-.08,5.23,0,7.84.06,1.73-.71,2.29-2.34,2.27a47.72,47.72,0,0,0-6.66.19c-3.28.41-4.33,1.44-4.72,4.73-.29,2.45-.13,5-.25,7.43-.08,1.65.51,2.39,2.24,2.34,2.75-.08,5.5-.06,8.24,0s2.84.25,2.61,2.65c-.3,3.12-.87,6.21-1.26,9.31a2.2,2.2,0,0,1-2.6,2.21q-3.33-.09-6.67,0c-2.52.07-2.6.12-2.61,2.72,0,8.63,0,17.26,0,25.89,0,1-.07,2.1,0,3.14.11,1.75-.77,2.26-2.37,2.23-3-.06-6-.1-9,0-1.91.07-2.49-.74-2.47-2.56.08-5,0-9.93,0-14.9Z"
    />
  </svg>
);

export default FacebookSvg;
