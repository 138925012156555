import React from 'react';
import styled from 'styled-components';
import StandardCarousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import ArrowSvg from 'icons/Arrow';

const Carousel = styled(StandardCarousel)`
  height: 100%;
`;

const CarouselImageWrapper = styled.div `
  width: 100%;
  display: flex;
  justify-content: center;
`;

const CarouselImage = styled.img `
  height: 560px;
  @media(max-width: 800px) {
    height: auto;
    width: 100%;
  }
`;

const Arrow = styled(ArrowSvg)`
  color: white;
  font-size: 2em;
  transform: rotate(${props => props.left ? 180 : 0}deg);
  position: absolute;
  ${props => props.left ? 'left: 1em' : 'right: 1em'};
  cursor: pointer;
  z-index: 1;
`;

const CarouselItem = ({src, alt}) => (
    <CarouselImageWrapper>
        <CarouselImage src={src} alt={alt}/>
    </CarouselImageWrapper>
);

const CarouselPage = ({
    config = []
}) => {
    return (
        <React.Fragment>
            <Carousel
                centered
                infinite
                slidesPerPage={1}
                clickToChange={true}
                autoPlay={3000}
                arrowLeft={<Arrow left />}
                arrowRight={<Arrow right />}
                addArrowClickHandler
                breakpoints={{
                1415: {
                    slidesPerPage: 1,
                    clickToChange: true,
                    centered: true
                },
                500: {
                    slidesPerPage: 1,
                    slidesPerScroll: 1,
                    centered: true
                }
            }}>
                {config.map(({
                    image,
                    altText
                }, i) => {
                    return <CarouselItem src={image} alt={altText}/>
                })
}
            </Carousel>
        </React.Fragment>
    );
};
export default CarouselPage;