import React from 'react';
import CarouselPage from 'pages/carousel-page';
import photosConfig from 'content-config/photos';
import styled from 'styled-components';
import withColor from 'util/hoc/with-color';
import getWithDefault from 'util/helpers/get-with-default';

const PageHeader = withColor(styled.h3`
  color: ${props => getWithDefault(props.colors.textPrimary, 'white')};
  font-family: 'bigpoint';
  font-size: 4em;
  font-weight: 100;
  font-weight: 100;
  align-self: center;
  margin: 0;
  @media(max-width: 600px) {
    margin-bottom: 1em;
  }
`);

const CarouselPageWrapper = styled.div `
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-self: center;
  flex: 1;
  width: 100%;
  margin-bottom: 4em;
  margin-top: -1em;
  @media(max-width: 1000px) {
    margin-top: 0em;
  }
`;

const Photos = () => {
  return (
    <CarouselPageWrapper>
      <PageHeader>{photosConfig.pageHeader}</PageHeader>
      <CarouselPage config={photosConfig.photos} />
    </CarouselPageWrapper>
  );
};
export default Photos;