import React from 'react';

const ArrowSvg = ({className}) => {
  return (
    <svg width="1em" className={className} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.16 220.05">
      <title>Arrow</title>
      <path fill="currentColor" class="cls-1" d="M0,31.66V17.06C0,1,10.7-4.85,23.85,4.41L129,76.92c8.27,5.84,12.17,13.63,12.17,23.86v18.49c0,10.22-3.42,17.53-12.17,23.85L23.85,215.64C10.7,224.89,0,219.05,0,203v-14.6C0,178.16,3.89,170.86,12.66,165l87.6-55L12.66,55C3.89,49.18,0,41.88,0,31.66Z"/>
    </svg>
  )
}

export default ArrowSvg;