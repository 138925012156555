import Slideshow1 from "images/photos/slideshow-1.jpeg";
import Slideshow2 from "images/photos/slideshow-2.jpeg";
import Slideshow3 from "images/photos/slideshow-3.jpg";
import Slideshow4 from "images/photos/slideshow-4.jpg";
import Slideshow5 from "images/photos/slideshow-5.jpg";
import Slideshow6 from "images/photos/slideshow-6.jpeg";
import Slideshow7 from "images/photos/slideshow-7.jpeg";
import Slideshow8 from "images/photos/slideshow-8.jpeg";
import Slideshow9 from "images/photos/slideshow-9.jpeg";
import Slideshow10 from "images/photos/slideshow-10.jpeg";
import Slideshow11 from "images/photos/slideshow-11.jpeg";
import Slideshow12 from "images/photos/slideshow-12.jpeg";
import Slideshow13 from "images/photos/slideshow-13.jpeg";
import Slideshow14 from "images/photos/slideshow-14.jpeg";
import Slideshow15 from "images/photos/slideshow-15.jpg";
import Slideshow16 from "images/photos/slideshow-16.jpeg";
import Slideshow17 from "images/photos/slideshow-17.jpeg";
import Slideshow18 from "images/photos/slideshow-18.jpeg";
import Slideshow19 from "images/photos/slideshow-19.jpg";
import Slideshow20 from "images/photos/slideshow-20.jpg";
import Slideshow21 from "images/photos/slideshow-21.jpeg";
import Slideshow22 from "images/photos/slideshow-22.jpeg";
import Slideshow23 from "images/photos/slideshow-23.jpeg";
import Slideshow24 from "images/photos/slideshow-24.jpeg";
import Slideshow25 from "images/photos/slideshow-25.jpeg";
import Slideshow26 from "images/photos/slideshow-26.jpeg";
import Slideshow27 from "images/photos/slideshow-27.jpeg";
import Slideshow28 from "images/photos/slideshow-28.jpg";
import Slideshow29 from "images/photos/slideshow-29.jpg";
import Slideshow30 from "images/photos/slideshow-30.jpg";
import Slideshow31 from "images/photos/slideshow-31.jpeg";
import Slideshow32 from "images/photos/slideshow-32.jpeg";
import Slideshow33 from "images/photos/slideshow-33.jpg";
import Slideshow34 from "images/photos/slideshow-34.jpg";
import Slideshow35 from "images/photos/slideshow-35.jpeg";
import Slideshow36 from "images/photos/slideshow-36.jpeg";
import Slideshow37 from "images/photos/slideshow-37.jpeg";
import Slideshow38 from "images/photos/slideshow-38.jpg";
import Slideshow39 from "images/photos/slideshow-39.jpeg";
import Slideshow40 from "images/photos/slideshow-40.jpeg";
import Slideshow41 from "images/photos/slideshow-41.jpeg";
import Slideshow42 from "images/photos/slideshow-42.jpeg";
import Slideshow43 from "images/photos/slideshow-43.jpeg";
import Slideshow44 from "images/photos/slideshow-44.jpg";
import Slideshow45 from "images/photos/slideshow-45.jpg";
import Slideshow46 from "images/photos/slideshow-46.jpg";
import Slideshow47 from "images/photos/slideshow-47.jpg";
import Slideshow48 from "images/photos/slideshow-48.jpg";
import Slideshow49 from "images/photos/slideshow-49.jpeg";
import Slideshow50 from "images/photos/slideshow-50.jpeg";
import Slideshow51 from "images/photos/slideshow-51.jpeg";
import Classes2 from "images/classes/classes-2.jpg";
import SeaTurtle from "images/photos/sea-turtle.jpg";

const photosConfig = {
  pageHeader: "MoonDance Photos",
  photos: [
    { image: Slideshow1, altText: "Slideshow 1" },
    { image: Slideshow2, altText: "Slideshow 2" },
    { image: Slideshow3, altText: "Slideshow 3" },
    { image: Slideshow4, altText: "Slideshow 4" },
    { image: Slideshow5, altText: "Slideshow 5" },
    { image: Slideshow6, altText: "Slideshow 6" },
    { image: Slideshow7, altText: "Slideshow 7" },
    { image: Slideshow8, altText: "Slideshow 8" },
    { image: Slideshow9, altText: "Slideshow 9" },
    { image: Slideshow10, altText: "Slideshow 10" },
    { image: Slideshow11, altText: "Slideshow 11" },
    { image: Slideshow12, altText: "Slideshow 12" },
    { image: Slideshow13, altText: "Slideshow 13" },
    { image: Slideshow14, altText: "Slideshow 14" },
    { image: Slideshow15, altText: "Slideshow 15" },
    { image: Slideshow16, altText: "Slideshow 16" },
    { image: Slideshow17, altText: "Slideshow 17" },
    { image: Slideshow18, altText: "Slideshow 18" },
    { image: Slideshow19, altttext: "Slideshow 19" },
    { image: Slideshow20, altttext: "Slideshow 20" },
    { image: Slideshow21, altttext: "Slideshow 21" },
    { image: Slideshow22, altttext: "Slideshow 22" },
    { image: Slideshow23, altttext: "Slideshow 23" },
    { image: Slideshow24, altttext: "Slideshow 24" },
    { image: Slideshow25, altttext: "Slideshow 25" },
    { image: Slideshow26, altttext: "Slideshow 26" },
    { image: Slideshow27, altttext: "Slideshow 27" },
    { image: Slideshow28, altttext: "Slideshow 28" },
    { image: Slideshow29, altttext: "Slideshow 29" },
    { image: Slideshow30, altttext: "Slideshow 30" },
    { image: Slideshow31, altttext: "Slideshow 31" },
    { image: Slideshow32, altttext: "Slideshow 32" },
    { image: Slideshow33, altttext: "Slideshow 33" },
    { image: Slideshow34, altttext: "Slideshow 34" },
    { image: Slideshow35, altttext: "Slideshow 35" },
    { image: Slideshow36, altttext: "Slideshow 36" },
    { image: Slideshow37, altttext: "Slideshow 37" },
    { image: Slideshow38, altttext: "Slideshow 38" },
    { image: Slideshow39, altttext: "Slideshow 39" },
    { image: Slideshow40, altttext: "Slideshow 40" },
    { image: Slideshow41, altttext: "Slideshow 41" },
    { image: Slideshow42, altttext: "Slideshow 42" },
    { image: Slideshow43, altttext: "Slideshow 43" },
    { image: Slideshow44, altttext: "Slideshow 44" },
    { image: Slideshow45, altttext: "Slideshow 45" },
    { image: Slideshow46, altttext: "Slideshow 46" },
    { image: Slideshow47, altttext: "Slideshow 47" },
    { image: Slideshow48, altttext: "Slideshow 48" },
    { image: Slideshow49, altttext: "Slideshow 49" },
    { image: Slideshow50, altttext: "Slideshow 50" },
    { image: Slideshow51, altttext: "Slideshow 51" },
    { image: Classes2, altttext: "Slideshow 52" },
    { image: SeaTurtle, altttext: "sea turtle" },
  ],
};

export default photosConfig;
