import React from 'react';
import { StyledAppWrapper } from 'wrappers/app/styles';
import Navigation from 'components/navigation';
import Footer from 'components/footer';
import 'styles/fonts.css';


const AppWrapper = ({ children }) => (
  <StyledAppWrapper>
    <Navigation height='140px'/>
    { children }
    <Footer />
  </StyledAppWrapper>
);

export default AppWrapper;