import React, { Fragment } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import GlobalStyles from 'global-styles';
import AppWrapper from 'wrappers/app';
import Home from 'pages/home';
import Classes from 'pages/classes';
import Photos from 'pages/photos';
import Contact from 'pages/contact';
import { EatMorePlants } from 'pages/eat-more-plants';

import SiteAudio from 'audio/carol.mp3';
import VolumeOffSvg from 'icons/volume-off';
import VolumeOnSvg from 'icons/volume-up';
import styled from 'styled-components';

const AudioContainer = styled.div`
  color: white;
  position: fixed;
  bottom: 3em;
  left: 1em;
  width: 50px;
  height: 50px;
  svg {
    cursor: pointer;
    z-index: 100;
  }
`;

class AudioController extends React.Component {
  constructor(props) {
    super(props);
    this.volumeRef = React.createRef();
    this.state = {
      isPaused: false,
    };
  }

  toggleVolume = () => {
    this.volumeRef.current.paused
      ? this.volumeRef.current.play()
      : this.volumeRef.current.pause();
    this.setState({ isPaused: this.volumeRef.current.paused });
  };

  render() {
    return (
      <AudioContainer onClick={this.toggleVolume}>
        {this.state.isPaused ? (
          <VolumeOffSvg
            width={'2em'}
            height={'2em'}
            onClick={this.toggleVolume}
          />
        ) : (
          <VolumeOnSvg
            width={'2em'}
            height={'2em'}
            onClick={this.toggleVolume}
          />
        )}
        <audio ref={this.volumeRef} autoPlay loop>
          <source src={SiteAudio} type="audio/mpeg" />
        </audio>
      </AudioContainer>
    );
  }
}

const Router = () => (
  <Fragment>
    {/* <AudioController /> */}
    <GlobalStyles />
    <BrowserRouter>
      <AppWrapper>
        <Switch>
          <Route path="/home" component={Home} />
          <Route path="/classes" component={Classes} />
          <Route path="/photos" component={Photos} />
          <Route path="/contact" component={Contact} />
          <Route path="/eat-more-plants" component={EatMorePlants} />
          <Redirect to="/home" />
        </Switch>
      </AppWrapper>
    </BrowserRouter>
  </Fragment>
);

export default Router;
