import { createGlobalStyle } from 'styled-components';
import withColor from 'util/hoc/with-color';
import getWithDefault from 'util/helpers/get-with-default';

export default withColor(createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  body, html {
    margin: 0;
    padding: 0;
    min-width: 100%;
    min-height: 100vh;
    font-size: 14px;
    line-height: 1.42857143;
    color: rgb(51, 51, 51);
    background-color: ${(props) =>
      getWithDefault(props.colors.darkPrimary, 'white')};
    height: 100%;
    @media(max-width: 600px) {
      font-size: 12px;
    }
  }


  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    top: 40px;
    right: 36px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: ${(props) =>
      getWithDefault(props.colors.textPrimary, '#373a47')};;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: ${(props) =>
      getWithDefault(props.colors.lightPrimary, '#a90000')};
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    top: 0;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }  
`);
