import React, { useState } from 'react';
import styled from 'styled-components';
import withColor from 'util/hoc/with-color';
import getWithDefault from 'util/helpers/get-with-default';
import { NavLink } from 'react-router-dom';
import MoondanceLogo from 'images/Logo.png';
import { slide as Menu } from 'react-burger-menu';

const NavContainer = styled.div`
  height: ${(props) => getWithDefault(props.height, '120px')};
  margin-bottom: 4em;
  z-index: 1;
`;

const NavBar = withColor(styled.nav`
  width: 100%;
  height: ${(props) => getWithDefault(props.height, '120px')};
  background-color: ${(props) =>
    getWithDefault(props.colors.darkPrimary, '#0b98de')};
  display: flex;
  position: fixed;
  align-items: center;
`);

const NavLinks = withColor(styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border-bottom: 1.25em solid
    ${(props) => getWithDefault(props.colors.lightPrimary, '#493092')};
  div {
    flex: 1;
  }

  @media (max-width: 800px) {
    display: none;
  }
`);

const StyledNavLink = withColor(styled(NavLink)`
  color: ${(props) => getWithDefault(props.colors.textPrimary, 'white')};
  &.active {
    color: ${(props) => getWithDefault(props.colors.lightPrimary, 'white')};
  }
  :hover {
    color: ${(props) => getWithDefault(props.colors.lightPrimary, '#efefef')};
    transition: all .3s ease-in-out
    transform: scale(1.1);
  }
  font-family: 'bigpoint';
  margin: 0em;
  font-size: 3em;
  font-weight: 100;
  text-decoration: none;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) => getWithDefault(props.alignOption, 'center')};
`);

const Logo = withColor(styled(NavLink)`
  height: 100%;
  min-width: 150px;
  width: 20%;
  background-color: ${(props) =>
    getWithDefault(props.colors.darkPrimary, 'black')};
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1.25em solid
    ${(props) => getWithDefault(props.colors.defaultPrimary, '#493092')};
  padding: 0.5em;
`);

const MobileNavContainer = styled.div`
  @media (min-width: 800px) {
    display: none;
  }
`;

const DesktopNavContainer = styled.div`
  @media (max-width: 800px) {
    display: none;
  }
`;

const Navigation = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <NavContainer height={props.height} id="namvcontainer">
      <NavBar height={props.height}>
        <Logo to="/home">
          <img src={MoondanceLogo} alt="MoonDance" height="100%" />
        </Logo>
        <NavLinks>
          <div>
            <StyledNavLink to="/home">Home</StyledNavLink>
          </div>
          <div>
            <StyledNavLink to="/classes">Classes / Retreats</StyledNavLink>
          </div>
          <div>
            <StyledNavLink to="/photos">Photos</StyledNavLink>
          </div>
          <div>
            <StyledNavLink to="/eat-more-plants">Eat More Plants</StyledNavLink>
          </div>
          <div>
            <StyledNavLink to="/contact">Contact</StyledNavLink>
          </div>
        </NavLinks>
        <MobileNavContainer>
          <Menu
            right
            width={'90%'}
            isOpen={menuOpen}
            onStateChange={(state) => setMenuOpen(state.isOpen)}
          >
            <div>
              <StyledNavLink to="/home" onClick={() => setMenuOpen(false)}>
                Home
              </StyledNavLink>
            </div>
            <div>
              <StyledNavLink to="/classes" onClick={() => setMenuOpen(false)}>
                Classes / Retreats
              </StyledNavLink>
            </div>
            <div>
              <StyledNavLink to="/photos" onClick={() => setMenuOpen(false)}>
                Photos
              </StyledNavLink>
            </div>
            <div>
              <StyledNavLink
                to="/eat-more-plants"
                onClick={() => setMenuOpen(false)}
              >
                Eat More Plants
              </StyledNavLink>
            </div>
            <div>
              <StyledNavLink to="/contact" onClick={() => setMenuOpen(false)}>
                Contact
              </StyledNavLink>
            </div>
          </Menu>
        </MobileNavContainer>
      </NavBar>
    </NavContainer>
  );
};

export default Navigation;
