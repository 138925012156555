
import React from 'react';
import styled from 'styled-components';
import getWithDefault from 'util/helpers/get-with-default';
import withColor from 'util/hoc/with-color';
import Phone from 'icons/Phone';
import Facebook from 'icons/Facebook';
import LinkedIn from 'icons/LinkedIn';

const PhoneSvg = styled(Phone)`
  width: 1.5em;
`;

const PlainATag = styled.a`
  color: white;
  &.active {
    color: white;
  }
  :hover {
    color: white;
    transition: all .3s ease-in-out
    transform: scale(1.01);
  }
`;

const SocialMediaLink = ({url, children}) => {
  return (
    <PlainATag href={url} target="_blank">
      {children}
    </PlainATag>
  );
}

const FacebookSvg = styled(Facebook)`
  width: 1em;
`;

const LinkedInSvg = styled(LinkedIn)`
  font-size: 20px;
`;

const InfoContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SocialMediaContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
`;

const FooterRow = withColor(styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  background-color: ${props => getWithDefault(props.colors.defaultPrimary, '#493092')};
  color: white;
  padding: 0 1em;
`);

const Footer = () => (
  <FooterRow>
    <InfoContainer>
    <PhoneSvg /> &nbsp; 773.655.8913, Louisville, CO
    </InfoContainer>
    <SocialMediaContainer>

    <SocialMediaLink url="https://www.facebook.com/moondancehealth/#_=_">
      <FacebookSvg />
    </SocialMediaLink>
    &nbsp;
    <SocialMediaLink url="https://www.linkedin.com/in/carolevanspoynter/">
      <LinkedInSvg />
    </SocialMediaLink>

    </SocialMediaContainer>
  </FooterRow>
);

export default Footer;