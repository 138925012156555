import Classes1 from "images/classes/classes-1.jpg";
import Classes3 from "images/classes/classes-3.jpg";
import Classes4 from "images/classes/classes-4.jpg";
import Classes5 from "images/classes/classes-5.jpg";
import Classes6 from "images/classes/classes-6.jpg";

const classesConfig = [
  {
    panel: {
      header: null,
      imageUrl: Classes1,
      imageRight: true,
      panelTheme: "light",
      imageWidth: "60%",
      body: [
        {
          header: "Lifelong Friends »",
          content:
            "Perhaps the sweetest gift of retreats and even regular classes is the connection that occurs between like-minded individuals. Friendships grow through the bond of yoga and the gift of a spiritual adventure. Even if yoga is not a routine practice for you, coming to a new class or a retreat offers you the chance to create lasting relationships with other beautiful souls.",
        },
      ],
    },
  },
  {
    panel: {
      header: null,
      imageUrl: Classes6,
      imageRight: false,
      marginTop: null,
      marginBottom: "0",
      imageWidth: "50%",
      panelTheme: "dark",
      body: [
        {
          header: "« What's New",
          content: [
            "Relax and Recharge in sunny Florida, February 22-25, 2024. The MoonDance Yoga Health & Wellness Retreat is back and we'd love to see you this winter. Escape the cold weather or just escape from your busy life for a few days as we enjoy a beautiful setting on the Indian River Lagoon in Melbourne, FL.",
            "",
            "",
            "Join me for a truly magical multi-day opportunity to re-discover your sense of wonder, gratitude for nature and your beautiful self-care rituals. Need to stretch and sweat? We'll get into some fabulous asana practice. Want to watch the sunrise or sunset? We'll have several chances to see both. Want to walk barefoot in the sand at the ocean and look at the sandpipers? Or maybe just relax with a cup of tea or coffee and enjoy the dolphins as they frolic around the lagoon. We will have brunch every day after a morning practice Friday, Saturday and Sunday. After a mid-day break, we'll reconvene for an afternoon activity - maybe a self-care demo or a healing sound experience. And of course there will be plenty of time for journaling and open discussion, as well as free time to explore the area, shop or just take a nap.",
            "",
            "",
            "I'm reaching out to my contacts and putting our program together over the next few months,  but we'll meditate for sunrise at least one morning and go for a silent walk one afternoon. As usual, there will be some fun surprises.",
            "",
            "",
            "And volleyball players, we could set a system up somewhere and play. Cocoa Beach has the best play space - it's about 30 minutes north.",
            "",
            "",
            "**Cost:**",
            "Early Bird sign up by December 1 = $550.00",
            "Sign up after December 1 = $600.00",
            "",
            "",
            "Contact carolevanspoynter@gmail.com with questions or to sign up.",
          ],
          textScroll: true,
          maxHeight: "450px",
          mobileMaxHeight: "350px",
        },
      ],
    },
  },
  {
    panel: {
      header: null,
      imageUrl: Classes3,
      imageRight: true,
      marginTop: "0",
      marginBottom: null,
      imageWidth: "45%",
      panelTheme: "light",
      body: [
        {
          header: "Past Events »",
          content: [
            "Escape to the Rockies, September 20-23, 2020, Colorado",
            "",
            "",
            "Walk in Balance, March 2019, Colorado",
            "",
            "",
            "Yes & No, March 2018, Colorado",
            "",
            "",
            "Ignite Your Inner Fire, June 2017, Colorado",
            "",
            "",
            "Time for Me to Fly, March 2017, Colorado",
            "",
            "",
            "Dance With Your Soul, February 2016, Florida",
            "",
            "",
            "Connect to Your Heart, February 2015, Florida",
            "",
            "",
            "Hello Inner Fire, February 2014, Big Island, Hawaii",
            "",
            "",
            "Summer Solstice Celebration, June 2013, Chicago",
            "",
            "",
            "Play, Play, Play, March 2013, Florida",
            "",
            "",
            "Winter Solstice Celebration, December 2012, Florida",
            "",
            "",
            "Practice on the Beach, May 2012, Florida",
            "",
            "",
            "Heart's Desire, March 2012, Florida",
            "",
            "",
            "Exploring Your Chakras, March 2011, Florida",
            "",
            "",
            "Hip Hop Yoga for Teens, June 2010, Florida",
            "",
            "",
            "Soak Up the Sun, February 2010, Florida",
            "",
            "",
            "Winter Solstice Celebration - Letting Go, December 2009, Chicago",
            "",
            "",
            "Hip Hop Yoga for Kids, May 2009, Skokie, Illinois",
            "",
            "",
            "Celebrate Spring - Focus on the Moon, March 2009, Florida",
            "",
            "",
            "Celebrate Night & Day, March 2008, Florida",
            "",
            "",
            "1st Annual Spring Equinox Retreat, February 2007, Florida",
            "",
            "",
            "Practice on the Beach, January 2007, Florida",
            "",
            "",
            "Chocolate-Covered Karma, September 2006, Chicago",
          ],
          textScroll: true,
          maxHeight: "250px",
        },
      ],
    },
  },
  {
    panel: {
      header: null,
      imageUrl: Classes4,
      imageRight: false,
      marginTop: null,
      marginBottom: null,
      imageWidth: "45%",
      panelTheme: "dark",
      body: [
        {
          header: "« Retreats",
          content: [
            "Want to refresh and rejuvenate your mind and body and get away for a few days? Join me for a retreat!  I host 4-day retreats in Florida, Hawaii and Colorado.  Each themed retreat lets us dive into a deeper practice, where time is taken to gain new perspectives on the poses you practice in your home or studio. You also get some special activities as part of the retreat: vegan cooking or kombucha-making classes with amazing chefs; and self-care — we've made our own facial scrub and serum, and learned the benefits of dry brushing. We also hike and practice walking meditations, sunrise and sunset meditations.  Finally, we always have time for journaling and even art projects for those who love to bring colored markers.  The gorgeous settings and quiet time fosters deep discussions of the issues we all face, and my friends return year after year to enjoy camaraderie and regain a sense of inner peace.",
            "",
            "",
            "**Upcoming Retreats**",
            "Find Inner Peace; Enjoy Outer Glow, February 22-25, 2024, Florida",
          ],
          textScroll: true,
          maxHeight: "450px",
        },
      ],
    },
  },
  {
    panel: {
      header: null,
      imageUrl: Classes5,
      imageRight: true,
      marginTop: null,
      marginBottom: null,
      imageWidth: "45%",
      panelTheme: "light",
      body: [
        {
          header: "Classes »",
          content: [
            "All classes are semi-private or private. This allows me to focus on YOU and lets you go deeper into your practice.  My yoga room is well ventilated and stays comfy.  As soon as temps rise enough, we'll resume classes outside if desired.",
            "- Private class at Owl Drive     ... $40/per class",
            "- Private class at your space    ... $90/per person",
            "- Semi-private at Owl Drive      ... $30/per person",
            "- Semi-private at your space     ... $45/per person",
            "",
            "Please call 773.655.8913 or reach out through email carolevanspoynter@gmail.com to inquire about a time or find out how this might benefit you.",
          ],
        },
      ],
    },
  },
];

export default classesConfig;
