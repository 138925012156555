import Markdown from 'markdown-it';

const TargetBlankPlugin = (md) => {
  md.core.ruler.after(
    'inline',
    'add-target-blank',
    (state) => {
      state.tokens.forEach((token) => {
        if (token.type === 'inline' && token.children) {
          token.children.forEach((child) => {
            if (child.type === 'link_open') {
              child.attrs.push(['target','_blank']);
            }
          });
        }
      });
    }
  );
};

const md = (new Markdown({
  breaks: true,
  linkify: true
})).use(TargetBlankPlugin);

export default {
  renderInline: (str='') => (
    md.renderInline((str||'').replace(/\\n/g, '\n'))
  ),
  render: (str='') => (
    md.render((str||'').replace(/\\n/g, '\n'))
  )
};
