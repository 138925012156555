import React from 'react';
import ColorContext from 'context/color';

export default (Component) => {
  class WithColor extends React.Component{
    render () {
      const { forwardedRef } = this.props;
      return (
        <ColorContext.Consumer>
          {(color) => (
            <Component {...this.props} ref={forwardedRef} colors={color} />
          )}
        </ColorContext.Consumer>
      );
    }
  }

  WithColor.displayName = `WithColor(${Component.displayName||Component.name})`;

  return React.forwardRef((props, ref) => {
    return <WithColor {...props} forwardedRef={ref}/>;
  });
};