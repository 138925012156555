import React from "react";
import Panel from "components/panel";

const PanelPage = ({ config = [] }) => {
  return (
    <React.Fragment>
      {config.map(({ panel }, i) => {
        return (
          <Panel
            key={i}
            imageRight={panel.imageRight}
            imageUrl={panel.imageUrl}
            header={panel.header}
            content={panel.body}
            {...panel}
          />
        );
      })}
    </React.Fragment>
  );
};
export default PanelPage;
