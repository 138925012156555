import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import withColor from 'util/hoc/with-color';
import getWithDefault from 'util/helpers/get-with-default';

const ButtonLink = withColor(styled(NavLink)`
  border: 1px solid white;
  padding: 0.5em 0em;
  color: ${(props) => getWithDefault(props.colors.textPrimary, 'white')};
  &.active {
    color: ${(props) => getWithDefault(props.colors.textPrimary, 'white')};
  }
  :hover {
    color: ${(props) => getWithDefault(props.colors.textPrimary, 'white')};
    transition: all 0.3s ease-in-out;
    transform: scale(1.01);
  }
  font-size: 1.5em;
  margin: 0em;
  font-weight: 100;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) => getWithDefault(props.alignOption, 'center')};
`);

export const ButtonLinkA = withColor(styled.a`
  border: 1px solid white;
  padding: 0.5em 0em;
  color: ${(props) => getWithDefault(props.colors.textPrimary, 'white')};
  &.active {
    color: ${(props) => getWithDefault(props.colors.textPrimary, 'white')};
  }
  :hover {
    color: ${(props) => getWithDefault(props.colors.textPrimary, 'white')};
    transition: all 0.3s ease-in-out;
    transform: scale(1.01);
  }
  font-size: 1.5em;
  margin: 0em;
  font-weight: 100;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) => getWithDefault(props.alignOption, 'center')};
`);

export default ButtonLink;
