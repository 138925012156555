import React from "react";
import styled from "styled-components";
import getWithDefault from "util/helpers/get-with-default";
import withColor from "util/hoc/with-color";
import Markdown from "util/helpers/markdown";

const PanelContainer = withColor(styled.div`
  width: 100%;
  display: flex;
  min-height: ${(props) => getWithDefault(props.height, "400px")};
  background-color: ${(props) =>
    props.panelTheme === "light"
      ? getWithDefault(props.colors.lightPrimary, "#ec098d")
      : getWithDefault(props.colors.defaultPrimary, "#493092")}
  margin: ${(props) => getWithDefault(props.marginTop, "5.5em")} 0 ${(props) =>
  getWithDefault(props.marginBottom, "5.5em")} 0;
  margin-top:  ${(props) => getWithDefault(props.marginTop, "5.5em")};
  flex-direction: ${(props) => (props.imageRight ? "row" : "row-reverse")};
  flex-wrap: wrap;
`);

const PanelContent = withColor(styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-self: center;
  align-items: center;
  padding: 1em 6em;
  flex: 1;
  width: 100%;
  color: ${(props) => getWithDefault(props.colors.textPrimary, "black")};
  @media (max-width: 1000px) {
    width: 100%;
    padding: 1em 1em;
  }
`);

const PanelImage = styled.img`
  width: ${(props) => getWithDefault(props.imageWidth, "40%")};
  background-size: cover;
  height: ${(props) => getWithDefault(props.imageHeight, "auto")};
  @media (max-width: 1000px) {
    width: 100%;
    height: auto;
  }
`;

const PanelHeader = styled.h2`
  margin: 0;
  font-size: 3em;
  font-weight: 100;
`;

const getMaxHeight = ({ maxHeight = "400px" }) => `max-height: ${maxHeight};`;

const SubContentRow = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  h4 {
    font-family: "bigpoint";
    margin: 0em;
    font-size: 3em;
    font-weight: 100;
  }
  div {
    /* width */
    ::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: black;
      border-radius: 5px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    ${(props) => (props.textScroll ? `overflow-y: auto;` : null)}
    ${(props) => (props.textScroll ? getMaxHeight(props) : null)}
  }
  @media (max-width: 1000px) {
    div {
      max-height: ${(props) =>
        props.mobileMaxHeight ? props.mobileMaxHeight : "450px"};
    }
  }
  span {
    display: flex;
    align-items: ${(props) => getWithDefault(props.alignItems, "initial")};
    flex: 1;
    font-size: 1.5em;
    min-height: 10px;
    p {
      min-height: 10px;
      margin: 0;
    }
    a {
      color: inherit;
    }
    ul {
      margin: 0 0.5em;
    }
  }
`;

const AlertWellPanel = withColor(styled.div`
  width: 60%;
  margin: auto;
  border-radius: 10px;
  box-shadow: 2px 5px 3px 2px #ec098d5e;
  min-height: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-self: center;
  align-items: center;
  padding: 1em 6em;
  flex: 1;
  color: ${(props) => getWithDefault(props.colors.textPrimary, "black")};
  background-color: ${(props) =>
      props.panelTheme === "light"
        ? getWithDefault(props.colors.lightPrimary, "#ec098d")
        : getWithDefault(props.colors.defaultPrimary, "#493092")}
    @media (max-width: 1000px) {
    width: 90%;
    padding: 1em 1em;
  }
`);

const PanelSubContent = ({
  header,
  content,
  textScroll,
  maxHeight,
  mobileMaxHeight,
}) => (
  <SubContentRow
    textScroll={textScroll}
    maxHeight={maxHeight}
    mobileMaxHeight={mobileMaxHeight}
  >
    <h4>{header}</h4>
    <div>
      {Array.isArray(content) ? (
        content.map((content, i) => (
          <span
            dangerouslySetInnerHTML={{ __html: Markdown.render(content) }}
            alignItems="center"
          />
        ))
      ) : (
        <span dangerouslySetInnerHTML={{ __html: Markdown.render(content) }} />
      )}
    </div>
  </SubContentRow>
);

const VideoPanelContainer = styled.div`
  display: flex;
  flex: 1;
  height: 500px;
  margin: 0 10%;
`;

const VideoPanel = ({ videoUrl }) => {
  return (
    <VideoPanelContainer>
      <iframe title={"stuff"} width="100%" src={videoUrl}></iframe>
    </VideoPanelContainer>
  );
};

const ContentPanel = ({
  header,
  content,
  hideImage,
  imageUrl,
  imageWidth,
  imageHeight,
  imagePosition,
}) => {
  return (
    <React.Fragment>
      <PanelContent>
        <PanelHeader>{header}</PanelHeader>
        {content.map((subcontent, i) => (
          <PanelSubContent key={i} {...subcontent} />
        ))}
      </PanelContent>
      {hideImage ? null : (
        <PanelImage
          src={imageUrl}
          imageWidth={imageWidth}
          imageHeight={imageHeight}
          imagePosition={imagePosition}
        />
      )}
    </React.Fragment>
  );
};

const Panel = ({
  header,
  imageUrl,
  content = [],
  panelTheme = "dark",
  imageRight = true,
  marginTop,
  marginBottom,
  imageWidth,
  imageHeight,
  imagePosition,
  hideImage = false,
  embeddedVideo = false,
  videoUrl,
  styleOverrides = {},
  type,
}) => {
  if (type === "Alert") {
    return (
      <AlertWellPanel
        panelTheme={panelTheme}
        imageRight={imageRight}
        marginTop={marginTop}
        marginBottom={marginBottom}
      >
        <ContentPanel
          header={header}
          content={content}
          hideImage={hideImage}
          imageUrl={imageUrl}
          imageWidth={imageWidth}
          imageHeight={imageHeight}
          imagePosition={imagePosition}
          style={styleOverrides}
          type={type}
        />
      </AlertWellPanel>
    );
  }
  return (
    <PanelContainer
      panelTheme={panelTheme}
      imageRight={imageRight}
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      {embeddedVideo ? (
        <VideoPanel videoUrl={videoUrl} />
      ) : (
        <ContentPanel
          header={header}
          content={content}
          hideImage={hideImage}
          imageUrl={imageUrl}
          imageWidth={imageWidth}
          imageHeight={imageHeight}
          imagePosition={imagePosition}
          style={styleOverrides}
          type={type}
        />
      )}
    </PanelContainer>
  );
};

export default Panel;
