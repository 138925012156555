import React from 'react';

const LinkedInSvg = ({ className }) => (
  <svg
    className={className}
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 75.75 72.62"
    width="1em"
    height="1em"
  >
    <title>Icons</title>
    <path
      fill="currentColor"
      className="cls-1"
      d="M26,47.68c0-7.06,0-14.12,0-21.18,0-3,.09-3,3.12-3.07q5.1-.08,10.2,0c3.19,0,3.2.08,3.33,3.29a11.37,11.37,0,0,0,0,1.17,9.4,9.4,0,0,0,.41,1.27,6.13,6.13,0,0,0,.84-.76c2.69-3.57,6.36-5.15,10.7-5.8,8.14-1.23,15.66,2.56,18.78,10.13a26.61,26.61,0,0,1,2.15,9.07c.3,9.41.18,18.83.16,28.24,0,2.45-.11,2.51-2.77,2.54q-5.49.09-11,0c-2.72,0-2.83-.13-2.85-2.94,0-7.32,0-14.64,0-22a27.78,27.78,0,0,0-.44-5.85c-1-4.48-3.82-6.47-8.18-6.18a8.11,8.11,0,0,0-7.67,7.09,23.06,23.06,0,0,0-.12,3.53c0,7.71,0,15.43,0,23.14,0,3-.09,3.06-3.11,3.1-3.39,0-6.79,0-10.19,0S26,72.44,26,69.26c0-7.19,0-14.38,0-21.58Z"
    />
    <path
      fill="currentColor"
      className="cls-1"
      d="M17.53,48.25c0,7.07,0,14.13,0,21.19,0,3.06-.08,3.1-3,3.14-3.66,0-7.32,0-11,0C1,72.53.88,72.46.86,69.88c0-6.28,0-12.56,0-18.84,0-8.11,0-16.22,0-24.32,0-3.06.07-3.1,3-3.14,3.66,0,7.32-.06,11,0,2.56,0,2.64.11,2.66,2.69C17.56,33.61,17.53,40.93,17.53,48.25Z"
    />
    <path
      fill="currentColor"
      className="cls-1"
      d="M0,8.49C.06,3.5,3.89,0,9.25,0s9.17,3.7,9.15,8.61c0,5.16-4.1,8.92-9.47,8.74S-.06,13.54,0,8.49Z"
    />
  </svg>
);

export default LinkedInSvg;
