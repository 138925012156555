import React from "react";
import styled from "styled-components";
import getWithDefault from "util/helpers/get-with-default";
import withColor from "util/hoc/with-color";
import MailIcon from "images/Mail.png";
import { ButtonLinkA } from "components/buttons/button-link";

const PanelContainer = withColor(styled.div`
  width: 100%;
  display: flex;
  height: 400px;
  min-height: ${(props) => getWithDefault(props.height, "400px")};
  margin: ${(props) => getWithDefault(props.marginTop, "5.5em")} 0
    ${(props) => getWithDefault(props.marginBottom, "5.5em")} 0;
  margin-top: ${(props) => getWithDefault(props.marginTop, "5.5em")};
  flex-direction: ${(props) => (props.imageRight ? "row" : "row-reverse")};
  flex-wrap: wrap;
  @media (max-width: 800px) {
    height: 100%; // ensure that the footer stays at the bottom
  }
`);

const PanelContent = withColor(styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em 6em;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  background-color: ${(props) =>
    props.panelTheme === "light"
      ? getWithDefault(props.colors.lightPrimary, "#ec098d")
      : getWithDefault(props.colors.defaultPrimary, "#493092")}
  width: ${(props) => (props.width ? props.width : "100%")};
  color: ${(props) => getWithDefault(props.colors.textPrimary, "black")};
  height: 100%;
  @media(max-width: 800px) {
    min-width: 100%;
    flex: 1;
    padding: 1em 1em;
  }
`);

const SubContentRow = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
  justify-content: center;
  width: 100%;
  font-size: 18px;
  padding: 1em;
  h4 {
    font-family: "bigpoint";
    margin: 0em;
    font-size: 3em;
    font-weight: 100;
  }
  @media (max-width: 800px) {
    align-items: center;
    text-align: center;
  }
`;

const SubContentSpan = styled.span`
  display: flex;
  font-size: 1.5em;
  text-align: right;
  width: 50%;
  @media (max-width: 1400px) {
    width: 100%;
  }
  @media (max-width: 800px) {
    text-align: center;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 4em;
  width: 20em;
  @media (max-width: 1200px) {
    margin-top: 2em;
  }
`;

const MailImg = styled.img`
  min-width: 300px;
  max-width: 60%;
  @media (max-width: 1200px) {
    width: 400px;
  }
`;

const PageContainer = styled.div`
  height: 100%;
`;

const PanelSubContent = ({ header, content, alignItems, children }) => (
  <SubContentRow alignItems={alignItems}>
    <h4>{header}</h4>
    <SubContentSpan>{content}</SubContentSpan>
    {children}
  </SubContentRow>
);

const Contact = ({
  panelTheme = "dark",
  imageRight = true,
  marginTop,
  marginBottom,
  imageWidth,
  imageHeight,
  imagePosition,
}) => (
  <PageContainer>
    <PanelContainer
      panelTheme={panelTheme}
      imageRight={imageRight}
      marginTop={marginTop}
      marginBottom={"16em"}
    >
      <PanelContent panelTheme="light" flex={3} minWidth="600px">
        <PanelSubContent
          alignItems="flex-end"
          header="Let's find a time to practice! >>"
          content={
            "I would love to help you find a time to practice with me or see you at the next retreat"
          }
        >
          <ButtonWrapper>
            <ButtonLinkA href="mailto:carolevanspoynter@gmail.com">
              Contact MoonDance Yoga
            </ButtonLinkA>
          </ButtonWrapper>
        </PanelSubContent>
      </PanelContent>
      <PanelContent panelTheme="dark">
        <MailImg src={MailIcon} alt="Contact me" />
      </PanelContent>
    </PanelContainer>
  </PageContainer>
);

export default Contact;
