import React from 'react';

export const DefaultColors = {
  darkPrimary: '#000',
  defaultPrimary: '#493092',
  lightPrimary: '#ec098d',
  textPrimary: '#FFFFFF',
  accent: '#00BCD4', 
  primaryText: '#212121',
  secondaryText: '#757575',
  divider: '#BDBDBD'
};

const ColorContext = React.createContext(
  DefaultColors
);

export default ColorContext;