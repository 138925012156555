
import styled from 'styled-components';

export const StyledAppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: black;
`;
export const StyledAppBody = styled.div`
  flex: 1;
`;