import React, { useState } from 'react';
import EarthDayRecipeBook from 'images/books/EarthDayRecipeBook.pdf';
import EarthDayRecipeBook2017 from 'images/books/EarthDayRecipeBook2017.pdf';

import EarthDayRecipeBook2017Thumbnail from 'images/books/EarthDayRecipeBook2017.PNG';
import EarthDayRecipeBookThumbnail from 'images/books/EarthDayRecipeBook.PNG';

import { Document, Page } from 'react-pdf';
import styled from 'styled-components';

import BioInfo from './BioInfo';

const PageIndicator = styled.p`
  color: white;
`;
const PDFWrapper = styled.div`
  display: block;
  height: auto;
  min-height: 650px;
  margin: 0 auto;
  position: relative;
  canvas {
    height: 650px !important;
    width: auto !important;
  }
  @media (max-width: 600px) {
    canvas {
      height: auto !important;
      width: 100% !important;
    }
    overflow-x: hidden;
  }
`;

const PageControlsDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  position: absolute;
  bottom: 10%;
  height: 300px;
  bottom: 8%;
  height: 300px;
  opacity: 0;
  align-items: flex-end;
  transition: opacity 0.3s ease-in-out;
  button {
    height: 25px;
  }
  span {
    margin: 0 1em;
  }
  &:hover {
    opacity: 1;
  }
`;

const BookChoicesDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const BookOptionsHolder = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  div {
    margin: 1em;
  }
`;
const BookOption = styled.div`
  width: 150px;
  height: 150px;
  background-color: white;
  background-image: url(${(props) => props.thumbnailUrl});
  background-size: cover;
  background-position: center;
  &:hover {
    transition: all 0.3s ease-in-out;
    transform: scale(1.1);
    cursor: pointer;
  }
`;

const BooksHeader = styled.h2`
  font-family: 'bigpoint';
  margin: 0;
  font-size: 3em;
  font-weight: 100;
  color: white;
`;

const PageControls = ({ handleNextPage, handlePreviousPage }) => {
  return (
    <PageControlsDiv>
      <button onClick={handlePreviousPage}>{`<`}</button>
      <span />
      <button onClick={handleNextPage}>{`>`}</button>
    </PageControlsDiv>
  );
};

const EatMorePlants = () => {
  const [currentBook, setCurrentBook] = useState(EarthDayRecipeBook);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const loadBook = (bookComponent) => {
    setCurrentBook(bookComponent);
    setPageNumber(1);
  };

  const handleNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  return (
    <React.Fragment>
      <BioInfo />

      <BookChoicesDiv>
        <BooksHeader>Recipe Books</BooksHeader>
        <BookOptionsHolder>
          <BookOption
            thumbnailUrl={EarthDayRecipeBookThumbnail}
            onClick={() => loadBook(EarthDayRecipeBook)}
          ></BookOption>
          <BookOption
            thumbnailUrl={EarthDayRecipeBook2017Thumbnail}
            onClick={() => loadBook(EarthDayRecipeBook2017)}
          ></BookOption>
        </BookOptionsHolder>
      </BookChoicesDiv>
      <PDFWrapper>
        <Document
          file={currentBook}
          onLoadSuccess={onDocumentLoadSuccess}
          options={{ workerSrc: 'pdf.worker.js' }}
        >
          <Page pageNumber={pageNumber} className={'pdfPage'} />
        </Document>
        <PageIndicator>
          Page {pageNumber} of {numPages}
        </PageIndicator>
        <PageControls
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
        />
      </PDFWrapper>
    </React.Fragment>
  );
};

export default EatMorePlants;
