import Home1 from "images/home/home-1.jpg";
import Home2 from "images/home/home-2.jpg";
import Home3 from "images/home/home-3.jpg";
import Home4 from "images/home/home-4.jpg";

const homeConfig = [
  {
    panel: {
      header: null,
      type: "Alert",
      hideImage: true,
      panelTheme: "light",
      imageWidth: "60%",
      body: [
        {
          header: "What's new...",
          content: [
            "Retreat scheduled Feb. 22-25, 2024 in Melbourne, FL. Details to follow soon. Email carolevanspoynter@gmail.com for more information.",
          ],
        },
      ],
      styleOverrides: {
        width: "60%",
        margin: "auto",
        borderRadius: "10px",
        boxShadow: "2px 5px 3px 2px #ec098d5e",
        minHeight: "auto",
      },
    },
  },
  {
    panel: {
      header: null,
      imageUrl: Home1,
      imageRight: true,
      panelTheme: "light",
      imageWidth: "33%",
      body: [
        {
          header: "Lighten up and set yourself free",
          content:
            "Carrying around worry and stress weighs you down. Find sweet release and liberation through yoga and mindfulness.",
        },
        {
          header: "Energize and ignite your inner fire",
          content:
            "Just a few minutes of mindful breathing allows fresh energy to either power you up or let you relax. Clear your mind and ignite your inner fire.",
        },
        {
          header: "Regain balance",
          content:
            "No one can stay balanced, but yoga helps you find it for a little while.",
        },
        {
          header: "Find freedom",
          content:
            "Love yourself now; turn off that negative voice and let your heart soar.",
        },
      ],
    },
  },
  {
    panel: {
      header: null,
      imageUrl: Home2,
      imageRight: false,
      body: [
        {
          header: "Carol Evans Poynter",
          content:
            "...teaches vinyasa flow yoga in Colorado, Florida and Chicago. She earned her teaching certification at the White Lotus Foundation in Santa Barbara, CA, and soon after created MoonDance Yoga. She has also completed many teacher trainings and yoga intensives with nationally known instructors like Shiva Rea, Desiree Rumbaugh, Cyndi Lee and Gary Kraftsow. She attended five Yoga Journal Live conferences in Illinois, San Francisco, Hollywood, FL (2x) and Estes Park, CO. These conferences offer longer classes with some of the best and brightest in the field, and also allowed for making new friends and contacts around the world.",
        },
      ],
    },
  },
  {
    panel: {
      header: null,
      imageUrl: Home3,
      imageRight: false,
      marginTop: null,
      marginBottom: "0",
      imageWidth: "45%",
      panelTheme: "light",
      body: [
        {
          header: "Classes / Retreats",
          content:
            "MoonDance Yoga offers a wide variety of classes and retreats for all skill levels. Email, text or call Carol to discuss your needs.",
        },
      ],
    },
  },
  {
    panel: {
      header: null,
      imageUrl: Home4,
      imageRight: true,
      marginTop: "0",
      marginBottom: null,
      imageWidth: "auto",
      imageHeight: "100%",
      body: [
        {
          header: "MoonDance Photos",
          content:
            "Take a photo tour through some of the best times at MoonDance Yoga - you will see the amazing souls who show up to practice, walk, get into nature, share stories of success and heartache, eat, drink, love and laugh.",
        },
      ],
    },
  },
];

export default homeConfig;
