import React from 'react';

const Phone = ({ className }) => (
  <svg
    id="Layer_1"
    className={className}
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 66.38 74.86"
  >
    <title>Icons</title>
    <path
      fill="currentColor"
      className="cls-1"
      d="M64.88,56.84C60.26,53.06,51,45.91,50.27,45.53c-3-1.58-7.18,6-8.35,7.93h0c-2.88,4-6.22,1.59-7.39.48v-.06h0c-5.14-4.8-11.79-13.36-15.4-18.19s-.83-7.43-.05-8l.16-.13h0a21.13,21.13,0,0,0,2-1.54,20.11,20.11,0,0,0,5.54-8.42,4.14,4.14,0,0,0-.54-3.85L17.32,1.66A4.08,4.08,0,0,0,11.61.8l0,0C8,3.5,2.87,8.19,1.09,14A29.49,29.49,0,0,0,.14,18.5a29.48,29.48,0,0,0,1,10.86A51,51,0,0,0,6,41.59,65.64,65.64,0,0,0,16.38,55.65s4.46,5,8,8.24c3.06,2.76,8.14,7.35,15.77,9.64,3,.91,6.4,1.88,10.58,1a19.24,19.24,0,0,0,8-4.24A29.43,29.43,0,0,0,65.83,62,4.06,4.06,0,0,0,64.88,56.84Z"
    />
  </svg>
);

export default Phone;
