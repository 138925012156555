const eatMorePlansConfig = [
  {
    panel: {
      header: null,
      hideImage: true,
      panelTheme: 'light',
      imageWidth: '60%',
      body: [
        {
          header:
            'Save the Earth, Save Yourself - The Case for Eating Mostly Plants',
          content: [
            "Many people would like to do more to fight climate change and help the environment, but feel powerless in tackling such a huge problem. We've learned that even recycling is not what it's cracked up to be in many areas; we can't all go immediately and buy an electric car, and although we're cheering Greta Thunberg and admire her strict dedication, we can't stop traveling.",
            '',
            '',
            "The challenges of climate change are daunting to be sure. But here's the good news: we can help the planet by eating more plants and fewer animals.",
            'A recent study in The Lancet concluded that a dietary shift toward plant foods and away from animal products is vital for human health and the health of our planet. (Good Medicine Spring 2021). The researchers found that food production is responsible for up to 30% of total greenhouse gas emissions, with animal products accounting for the vast majority of these effects.',
            '',
            '',
            '"If cattle were a country, they would rank third in greenhouse gas emissions, behind China and the United States,” says Leslie A. Duram, PhD, a professor of environmental studies at Southern Illinois University. "One cow produces as much methane as about 80 cars on the road.” Many people don\'t realize just how much their eating habits contribute to global warming and pollution. And eating is something most of us definitely can control.',
            '',
            '',
            'Consider that one fast-food quarter- pound burger:',
            '* uses 460 gallons of water',
            '* uses 64.5 square feet of land',
            '* adds 0.126 pounds methane (23x more potent than CO2)',
            '* and adds 4 pounds greenhouse gas emissions.',
            '',
            '',
            'By switching to a plant-based diet, we can not only save animals from extreme abuse in factory farms, but we can lessen the significant and detrimental environmental impact of animal agriculture, and prevent or reverse prevalent conditions like heart disease, Type 2 diabetes, obesity, and cancer.',
            'Physicians Committee for Responsible Medicine (PCRM) doctors and dietitians published a review in "Advances in Nutrition” that found plant-based diets are best for diabetes prevention and treatment. Research shows that eating fruits, vegetables, legumes and whole grains, while removing animal products, improve risk factors for diabetes, including blood sugar, cholesterol, weight, blood pressure and cardiovascular disease. (Good Medicine Autumn 2021)',
            '',
            '',
            'I became a vegetarian 30+ years ago and never looked back. I did it at first for my health; then I learned about the horrors of factory farms and became inspired to go vegan. And now, as we see the devastating effects of climate change and how a plant-based diet can really make a difference, I’m offering these simple recipes as a way to help you eat more plants.',
            '',
            '',
            'I’m an Imperfect Vegan, meaning that I sometimes have a little cheese or yogurt if I cannot find a substitute (usually when I am traveling or at someone’s house as a guest), and then there have also been times when I am just so hungry and the restaurant I’m in doesn’t have any good vegan alternatives. This is happening less frequently, though, as many restaurants are adding plant-based items to their menus. My animal advocate friends will probably shiver in horror at my slip ups, but I have still saved an estimated 4,000+ animals and I have also prevented 32,000+ pounds of CO2 from being released.',
            '',
            '',
            'These numbers are gratifying to me as an animal lover and as someone who wants to help save the earth. It’s not all or nothing - you CAN make a difference with the choices you make every single day.',
            '',
            '',
            'I hope you enjoy these recipes and that they might inspire you to add more plants into your diet.',
          ],
        },
      ],
    },
  },
  {
    panel: {
      embeddedVideo: true,
      videoUrl: 'https://www.youtube.com/embed/69tQFKG6v38',
    },
  },
];

export default eatMorePlansConfig;
